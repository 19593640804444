<template>
  <div>
    <v-app-bar
      v-if="!hiddenAppBar"
      class="print-dp-none"
      app
    >
      <div class="app-bar-title">
        <v-spacer></v-spacer>
        <template>
          <v-btn
            v-if="!isBlank && !isDraft && !isCanceled"
            small
            class="print-dp-none"
            @click="print()">
            <v-icon
              class="mr-2"
              small>
              mdi-printer
            </v-icon>
            <span>Print</span>
          </v-btn>
        </template>
      </div>
    </v-app-bar>

    <v-main>
      <v-form
        ref="form"
        v-model="isFormValid"
        @input="inputValidate($event)">
        <multi-page-a4
          :note-ref="noteRef"
          :note-id="noteCode"
          :products="products"
          :status="status"
          :print-by="printBy"
          :create-by="createBy"
          :out-of-limit="outOfLimit"
          :warehouse="warehouseSelected"
          :print-time="printTime"
          :note.sync="note"
          :update-by="updateBy"
          :reason.sync="reason"
          :bc-ref.sync="bcRef"
          :update-time="updateTime"
          :stock="stock"
          :count="count"
          note-type="withdrawNote"
          :transfer-warehouse-id.sync="transferWarehouseId"
          :warehouse-items="warehouseItems"
          :qr="qr"
          @remove-product="removeProduct($event)"
          @remove-sku="removeSku($event)">
          <template v-slot:title>
            <div class="page-a4-title">
              <h1 class="mr-2">
                ใบนำสินค้าออก
              </h1>
            </div>
          </template>
        </multi-page-a4>
      </v-form>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getAuthDecode, getRole } from '@/assets/js/Authentication'
import InventoryProvider from '@/resources/InventoryProvider'
import PurchaseOrdersProvider from '@/resources/PurchaseOrdersProvider'
import ProductProvider from '@/resources/ProductProvider'
import { generateQRCode } from '@/assets/js/helper'
import ProductCollectionProvider from '@/resources/ProductCollectionProvider'
import StockCountAdjustmentProvider from '@/resources/StockCountAdjustmentProvider'
import SizeValues from '@/assets/js/SizeValues'
import MultiPageA4 from '../components/MultiPageA4.vue'

const InventoryService = new InventoryProvider()
const PurchaseOrdersService = new PurchaseOrdersProvider()
const stockCountAdjustmentService = new StockCountAdjustmentProvider()
const ProductService = new ProductProvider()
const ProductCollectionService = new ProductCollectionProvider()

export default {
  components: {
    multiPageA4: MultiPageA4
  },
  data () {
    return {
      headers: [
        {
          text: 'Row',
          value: 'row'
        },
        {
          text: 'SKU',
          value: 'sku'
        },
        {
          text: 'QTY',
          value: 'qty'
        }
      ],
      collections: [],
      productsSelected: [],
      productsCounts: [],
      noteRef: [],
      status: [],
      productQr: '',
      printBy: getAuthDecode().email,
      noteCode: [],
      createBy: [],
      note: [],
      noteWarehouse: 0,
      qr: [],
      reason: [],
      printTime: new Date().toString(),
      updateBy: [],
      updateTime: [],
      loading: false,
      stock: [],
      validateFormTimeout: null,
      valid: false,
      transferNote: false,
      transferWarehouseId: [],
      ref: null,
      bcRef: [],
      isFormValid: false,
      showPrintQr: false,
      webUrl: 'gentlewomanonline.com',
      showImport: false,
      isReaded: false,
      validData: [],
      invalidData: [],
      showRFIDModal: false,
      aliasIds: {},
      poCreateDate: null,
      exporting: false,
      missingFitFields: [],
      loadingCalPrint: false,
      stockAdjustmentNote: null,
      saveClicked: false,
      products: [],
      countsFirst: [],
      showMultiPageA4: false,
      product: [],
      hiddenAppBar: false,
      purchaseOrderIds: [],
      assemblyOrderIds: []
    }
  },
  computed: {
    ...mapGetters({
      warehouse: 'Store/warehouse',
      storeSetting: 'Store/storeSetting',
      store: 'Store/store'
    }),
    warehouseItems () {
      return this.warehouse.filter((r) => r.id !== this.warehouseId && r.id !== 0)
    },
    isDisabledByRole () {
      const dnRole = [
        'management',
        'area_manager',
        'marketing',
        'developer',
        'creative',
        'merchandising_planner',
        'merchandiser',
        'online_admin',
        'vm',
        'accounting_manager',
        'inventory_and_costing',
        'warehouse_manager',
        'warehouse',
        'store_manager',
        'acting_assist_store_manager',
        'assist_store_manager',
        'sales_staff'
      ]
      const role = getRole()
      return !dnRole.some((r) => r === role)
    },
    isShowSetCount () {
      const role = getRole()
      const setCountRoles = [
        'auth',
        'management',
        'area_manager',
        'developer',
        'merchandising_planner',
        'merchandiser',
        'warehouse_manager',
        'warehouse',
        'inventory_and_costing'
        // 'store_manager',
        // 'assist_store_manager'
      ]
      return setCountRoles.some((r) => r === role)
    },
    isTransferNote () {
      return this.transferNote || (this.transferWarehouseSelected && this.transferWarehouseSelected.id > 0)
    },
    isDisableCreateBtn () {
      if (this.reason === 'STOCK_COUNT') {
        return !this.valid || this.productsSelected.length === 0 || this.bcRef === ''
      }
      return !this.valid || this.productsSelected.length === 0 || this.reason === null
    },
    isDisableSaveDraft () {
      return !this.valid || this.productsSelected.length === 0 || this.reason === null
    },
    warehouseSelected () {
      const id = this.warehouseId || 0
      return this.findWarehouse(id)
    },
    transferWarehouseSelected () {
      const id = this.transferWarehouseId || 0
      return this.findWarehouse(id)
    },
    warehouseId () {
      let warehouseId = 0
      if (this.noteWarehouse) {
        warehouseId = this.noteWarehouse
      } else if (this.$route.query.warehouse) {
        warehouseId = parseInt(this.$route.query.warehouse)
      }
      return warehouseId
    },
    purchaseOrderId () {
      return this.$route.query.poNo || null
    },
    stockAdjustmentNoteId () {
      return this.$route.query.stockAdjustmentNoteId || null
    },
    assemblyOrderId () {
      return this.$route.query.aoNo || null
    },
    noteId () {
      return this.$route.query.id || []
    },
    isDraft () {
      return this.status === 'draft'
    },
    isPending () {
      if (Array.isArray(this.status)) {
        return this.status.includes('pending') && this.reason !== 'ASSEMBLY_ORDER'
      }
      return this.status === 'pending' && this.reason !== 'ASSEMBLY_ORDER'
    },
    isCanceled () {
      return this.status === 'canceled'
    },
    isApproved () {
      return this.status === 'approved'
    },
    isBlank () {
      return this.status === ''
    },
    count () {
      // eslint-disable-next-line max-len
      return this.productsCounts.map((p) => p.reduce((groupSum, k) => groupSum + (Array.isArray(k.skus) ? k.skus.reduce((sum, sk) => sum + (sk.count || 0), 0) : 0), 0))
    },

    outOfLimit () {
      return this.productsSelected.some((pd) => !!pd.skus.some((sku) => sku.amount < sku.count))
    },
    isDisableBtnApprove () {
      return this.count <= 0 || this.outOfLimit
    },
    // isMobile () {
    //   return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    // },
    isShowFit () {
      return this.store?.id === '645a1d00765d5519beeb97f6'
    }
  },
  created () {
    this.initials()
  },
  mounted () {
    window.addEventListener('afterprint', this.handleAfterPrint)
  },
  beforeDestroy () {
    window.removeEventListener('afterprint', this.handleAfterPrint)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    initials () {
      window.onbeforeunload = () => 'Changes you made may not be saved.'
      window.onbeforeprint = () => {
        this.printTime = new Date().toString()
      }
      if (this.noteId) {
        this.saveClicked = true
        this.fetchNote()
      } else if (this.purchaseOrderId) {
        this.status = 'draft'
        this.fetchPurchaseOrder(this.purchaseOrderId)
      } else if (this.stockAdjustmentNoteId) {
        this.status = 'draft'
        this.fetchStockAdjustmentNote(this.stockAdjustmentNoteId)
      } else {
        this.status = 'draft'
      }
      this.getCollections()
    },
    async getCollections () {
      try {
        this.loading = true

        const { data } = await ProductCollectionService.getAll({
          page: 1,
          limit: 999,
          sortBy: 'createdAt',
          sortOrder: 'desc'
        })

        this.collections = data.results.map((c) => ({
          id: c.id,
          name: c.name,
          nickname: c.nickname
        }))
      } catch (error) {
        console.error('getCollections', error)
      } finally {
        this.loading = false
      }
    },
    findWarehouse (id) {
      const warehouse = this.warehouse.find((r) => +r.id === +id)
      return {
        id: warehouse.id,
        name: warehouse.name,
        code: warehouse.code
      }
    },
    inputValidate () {
      this.validateForm()
    },
    async getSkusAliasByCodes (codes) {
      try {
        this.setLoading({ active: true })
        const promises = await Promise.all(codes.map(async (code) => {
          const { data } = await ProductService.getSkuAliasIdByCode(code)

          return data
        }))

        this.aliasIds = promises.reduce((obj, v) => {
          const tmpObj = { ...obj }
          const code = Object.keys(v)[0]
          const aliasId = v[code]

          tmpObj[aliasId] = code.toUpperCase()

          return tmpObj
        }, {})
      } catch (error) {
        console.error('getSkusAliasByCodes', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      }
    },
    validateForm () {
      clearTimeout(this.validateFormTimeout)
      this.validateFormTimeout = setTimeout(() => {
        this.valid = this.$refs.form.validate()
      }, 50)
    },
    removeProduct (index) {
      this.productsSelected.splice(index, 1)
    },
    removeSku ({ productIndex, skuIndex }) {
      if (this.productsSelected[productIndex].skus.length > 1) {
        this.productsSelected[productIndex].skus.splice(skuIndex, 1)
      } else {
        this.removeProduct(productIndex)
      }
    },
    getFitting (tags) {
      if (this.isShowFit) {
        if (tags && tags.length) {
          const fittingTag = tags.find((tag) => ['UNISEX', 'WOMEN', 'KIDS', 'MEN'].includes(tag.toUpperCase()))
          if (fittingTag) {
            return `${fittingTag.toUpperCase()}`
          }
        }
        return 'FIT : -'
      }
      return ''
    },

    async fetchNote () {
      try {
        this.loading = true
        this.setLoading({ active: true })
        if (Array.isArray(this.noteId)) {
          const sortedIds = this.noteId.sort((a, b) => a - b).reverse()
          const results = await Promise.all(sortedIds.map(async (id) => {
            const { data } = await InventoryService.getWithdrawNoteById(id)
            const codes = data.products.reduce((arr, v) => {
              const skuCodes = v.skus.map((s) => s.code)

              return [...arr, ...skuCodes]
            }, [])

            await this.getSkusAliasByCodes(codes)
            if (data.status === 'draft' || data.status === 'pending') {
              const products = await this.getManyProductStock(data)
              this.stock.push(this.setProductStock(products))
            }
            return { id, data }
          }))
          const sortedResults = results.sort((a, b) => this.noteId.indexOf(a.id) - this.noteId.indexOf(b.id))
          sortedResults.forEach(({ data }) => this.setNoteData(data))
        } else {
          const { data } = await InventoryService.getWithdrawNoteById(this.noteId)
          const codes = data.products.reduce((arr, v) => {
            const skuCodes = v.skus.map((s) => s.code)

            return [...arr, ...skuCodes]
          }, [])

          if (data?.purchaseOrderId) {
            await this.fetchPurchaseOrder(data.purchaseOrderId, true)
          }

          await this.getSkusAliasByCodes(codes)
          await this.setNoteData(data)
          if (this.isDraft || this.isPending) {
            const products = await this.getManyProductStock(data)
            this.stock.push(this.setProductStock(products))
          }
        }
      } catch (error) {
        console.error('fetchNote', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.setLoading({ active: false })
        this.validateForm()
      }
    },
    async fetchPurchaseOrder (id, onlyDate = false) {
      try {
        this.loading = true

        const { data } = await PurchaseOrdersService.getItemByOrderId(id)

        this.poCreateDate = data?.createdAt ? this.$dayjs(data.createdAt).format('MM-YYYY') : null

        if (onlyDate) {
          return
        }

        const models = data.type === 'FG'
          ? data.purchaseOrderLines.map((p) => p.itemNo)
          : data.purchaseOrderLines.reduce((arr, p) => [...arr, ...p.useForProducts], [])
        const modelIds = [...new Set(models)]
        // const collections = []
        const products = []

        if (data.type === 'FG') {
          const { data: foundProducts } = await ProductService.getManyProductByCode({ codes: modelIds })

          const poCollections = []

          data.purchaseOrderLines.forEach((purchaseOrderLine) => {
            poCollections.push(purchaseOrderLine.gwCollection)
            const foundProduct = foundProducts.find((s) => {
              const [brand, fac, cat, sub, running, color] = purchaseOrderLine.itemNo.split('.')
              const model = `${brand}.${fac}.${cat}.${sub}.${running}.${color}`

              return model === s.model
            })

            const foundSku = foundProduct.skus.find((fs) => fs.code === purchaseOrderLine.itemNo)

            const sku = {
              ...foundSku,
              color: foundSku.color.name,
              size: foundSku.size.name,
              count: 0,
              poQty: purchaseOrderLine.quantity,
              receivedQty: purchaseOrderLine?.receivedQty || 0,
              amount: purchaseOrderLine.quantity - (purchaseOrderLine?.receivedQty || 0),
              maxAmount: purchaseOrderLine.quantity - (purchaseOrderLine?.receivedQty || 0),
              sequence: purchaseOrderLine.sequence
            }

            const index = products.findIndex((p) => p.id === foundProduct.id)
            if (index === -1) {
              products.push({
                id: foundProduct.id,
                model: foundProduct.model,
                name: foundProduct.name,
                photoUrl: foundProduct.photoUrls[0] || '',
                variant: foundProduct.variant,
                skus: [sku]
              })
            } else {
              products[index].skus.push(sku)
            }
          })

          this.reason = 'PURCHASE_ORDER'
          this.note = [...new Set(poCollections.filter((collection) => !!collection))].join(', ')
        } else {
          const { data: foundProducts } = await ProductService.getManyProductByModel({ modelIds })

          foundProducts.forEach((p) => {
            const skus = p.skus.map((sku, i) => ({
              ...sku,
              color: sku.color.name,
              size: sku.size.name,
              count: 0,
              poQty: 0,
              receivedQty: 0,
              amount: 0,
              maxAmount: 0,
              sequence: (i + 1) * 10000
            }))

            products.push({
              id: p.id,
              model: p.model,
              name: p.name,
              photoUrl: p.photoUrls[0] || '',
              variant: p.variant,
              skus
            })
          })

          this.reason = 'ASSEMBLY_ORDER'
        }

        const codes = products.reduce((arr, v) => {
          const skuCodes = v.skus.map((s) => s.code)

          return [...arr, ...skuCodes]
        }, [])

        await this.getSkusAliasByCodes(codes)
        this.productsSelected = products

        // if (!this.noteId) {
        //   this.note = [...new Set(collections)].join(', ')
        // }
      } catch (error) {
        console.error('fetchPurchaseOrder', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.validateForm()
      }
    },
    async getManyProductStock (data) {
      const products = await this.setProduct(data)
      const { data: product } = await ProductService.getManyProductById({
        productIds: products.map((r) => r.id)
      })
      return product
    },
    async setProduct (data) {
      const products = data.products.map((product) => {
        let skus = this.sortSize(product.skus)

        if (data.withdrawNoteRef) {
          const wnProduct = data.withdrawNoteRef.products.find((p) => p.id === product.id)

          skus = skus.map((sku) => {
            const wnSku = wnProduct?.skus?.find((s) => s.id === sku.id) || null

            return {
              ...sku,
              maxAmount: wnSku?.count || sku.maxAmount
            }
          })
        }

        return {
          ...product,
          skus
        }
      })

      return products
    },
    async setNoteData (data) {
      const products = await this.setProduct(data)

      this.qr.push(await generateQRCode(`${process.env.VUE_APP_URL}/notes/withdraw-note?id=${data.id}&warehouse=${this.noteWarehouse}`))
      this.noteCode.push(data.code)
      this.reason.push(data.reason || '-')
      this.bcRef.push(data.bcRef)
      this.status.push(data.status)
      this.noteRef.push(data.deliveryNoteRef)
      this.createBy.push(data.createdBy.email)
      this.updateBy.push(data.updatedBy.email)
      this.updateTime.push(new Date(data.approvedDate).toString())
      this.note.push(data.note)
      this.noteWarehouse = data.warehouse.id || 0
      this.productsSelected = []
      this.productsSelected.push(...products)
      this.products.push(products)
      this.product.push(products)
      if (!Array.isArray(this.noteId)) {
        this.productsCounts.push(...this.product)
      }
      if (this.product.length === this.noteId.length) {
        this.productsCounts.push(...this.product)
      }
      this.transferWarehouseId.push(this.mapRef(data))

      if (this.noteCode.length === this.noteId.length) {
        this.showMultiPageA4 = true
        this.$emit('loaded')
      }
    },
    mapRef (data) {
      if (data.deliveryNoteRef && data.deliveryNoteRef.warehouseId) {
        return data.deliveryNoteRef.warehouseId
      }
      return null
    },
    print () {
      this.hiddenAppBar = true
      this.printTime = new Date().toString()
      setTimeout(() => {
        window.print()
        this.hiddenAppBar = false
      }, 100)
    },
    async handleAfterPrint () {
      await Promise.all(this.noteId.map(async (id) => {
        await InventoryService.updatePrintedWithdrawNote(id)
      }))
      this.setSnackbar({
        value: true,
        message: 'Printed successfully',
        type: 'success'
      })
    },
    setProductStock (products) {
      const stock = []
      for (let index = 0; index < products.length; index++) {
        const product = products[index]
        for (let j = 0; j < product.skus.length; j++) {
          const sku = product.skus[j]
          stock.push({
            id: sku.id,
            stock: sku?.stock?.find((r) => r.warehouse.id === this.warehouseId) ?? null
          })
        }
      }
      return stock
    },
    undoReceive () {
      this.$router.replace({
        name: 'WithdrawNote',
        query: {
          dnNo: this.noteId,
          warehouse: this.noteWarehouse
        }
      })
    },
    sortSize (skus) {
      return skus.sort((a, b) => SizeValues(a.size) - SizeValues(b.size))
    },
    async fetchStockAdjustmentNote () {
      try {
        this.loading = true
        const { data: stockAdjustmentNote } = await stockCountAdjustmentService.getStockAdjustmentNote({
          stockAdjustmentNoteId: this.stockAdjustmentNoteId,
          noteType: 'dn'
        })
        this.stockAdjustmentNote = stockAdjustmentNote
        const products = []
        stockAdjustmentNote.products.forEach((p) => {
          const skus = p.skus.map((sku, i) => ({
            ...sku,
            sequence: (i + 1) * 10000
          }))

          products.push({
            id: p.id,
            model: p.model,
            name: p.name,
            photoUrl: p.photoUrls || '',
            variant: p.variant,
            skus
          })
        })

        this.reason = stockAdjustmentNote.reason

        const codes = products.reduce((arr, v) => {
          const skuCodes = v.skus.map((s) => s.code)

          return [...arr, ...skuCodes]
        }, [])

        await this.getSkusAliasByCodes(codes)

        this.productsSelected = products

        if (!this.noteId) {
          this.note = stockAdjustmentNote.note
        }
      } catch (error) {
        console.error('fetchPurchaseOrder', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.validateForm()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-bar-title {
  display: flex;
  width: 230mm;
  margin: 0 auto;
  > button {
    margin: 0 5px;
  }
}
.page-a4-title {
  display: flex;
  align-items: center;
}
.warehouse-selector {
  width: 200px;
  font-size: 14px;
}
</style>
